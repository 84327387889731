<template>
  <b-card title="İş Emri işlemleri">
    <div>
      <!-- search input -->
      <div class="d-flex justify-content-end">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'PamisOperatorJobs' }"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-25" />
          <span>Back to List</span>
        </router-link>
      </div>

      <b-card>
        <b-form>
          <b-row>
            <b-col cols="12" class="mb-2" />
            <b-col md="6">
              <b-form-group label="Makina" label-for="MachineId">
                <v-select
                  id="MachineId"
                  v-model="Job.machine"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="machines"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Name1"
                rules="required"
              >
                <b-form-group label="Job Order" label-for="JobID">
                  <v-select
                    id="JobId"
                    v-model="Job.id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jobs"
                    label="code"
                    placeholder="Select"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Start Date">
                <flat-pickr
                  id="targetStartDate"
                  v-model="Job.targetStartDate"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    locale: $i18n.t('locale_datePickr'),
                    time_24hr: true,
                    dateFormat: 'Y-m-d H:i',
                  }"
                  placeholder="Target Start Date"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Finish Date">
                <flat-pickr
                  id="targetFinishDate"
                  v-model="Job.targetFinishDate"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    locale: $i18n.t('locale_datePickr'),
                    time_24hr: true,
                    dateFormat: 'Y-m-d H:i',
                  }"
                  placeholder="Target Finish Date"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Note" label-for="Note">
                <b-form-textarea
                  id="Note"
                  v-model="Job.note"
                  placeholder="Note giriniz."
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <div class="d-flex justify-content-end">
        <button v-b-modal.modal-stoppage class="btn btn-primary">
          Add Stoppage
        </button>
      </div>
      <!-- table -->
      <b-table
        ref="refoperatorJobListTable"
        class="position-relative"
        :items="row"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(targetFinishDate)="data">
          {{ format_date(data.item.targetFinishDate) }}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="Delete(data.item)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobEfforts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <hr />

      <!-- submit and reset -->
      <div class="w100" />
      <b-col class="d-flex justify-content-end" md="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="formSubmitted"
        >
          Kaydet
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Temizle
        </b-button>
      </b-col>
      <b-modal
        id="modal-stoppage"
        cancel-variant="outline-secondary"
        ok-title="Kaydet"
        cancel-title="Kapat"
        centered
        title="Duruş Ekranı"
        size="lg"
        @ok="addStoppage"
      >
        <validation-observer ref="cargoRules" tag="form">
          <b-form>
            <b-form-group label="StoppageType" label-for="StoppageTypeId">
              <v-select
                id="StoppageTypeId"
                v-model="StoppageTypeId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="stoppageTypes"
                placeholder="Seçiniz"
              />
            </b-form-group>
            <b-form-group label="Start Date">
              <flat-pickr
                id="stoppageStartDate"
                v-model="stoppageStartDate"
                class="form-control"
                :config="{
                  enableTime: true,
                  locale: $i18n.t('locale_datePickr'),
                  time_24hr: true,
                  dateFormat: 'Y-m-d H:i',
                }"
                placeholder="Stoppage Start Date"
              />
            </b-form-group>
            <b-form-group label="Finish Date">
              <flat-pickr
                id="stoppageFinishDate"
                v-model="stoppageFinishDate"
                class="form-control"
                :config="{
                  enableTime: true,
                  locale: $i18n.t('locale_datePickr'),
                  time_24hr: true,
                  dateFormat: 'Y-m-d H:i',
                }"
                placeholder="Stoppage Finish Date"
              />
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  inject,
  toRefs,
  watch,
  ref,
  onUnmounted,
  computed,
} from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import jobStoreModule from "../taskManagementStoreModule";
import useOperatorJobList from "./useOperatorJobList";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTable,
    BRow,
    BCol,
    BPagination,
    BDropdown,
    BDropdownItem,
    flatPickr,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  model: {
    event: "update:is-add-new-job-sidebar-active",
  },
  props: {},

  data() {
    return {
      required,
      alphaNum,
      machine: null,
      priority: null,
      id: null,
      mouldId: null,
      description: null,
      category: null,
      pieces: null,
      targetFinishDate: null,
      responsibleUserId: null,
      requestDate: null,
      requesterUser: null,
      requestDescription: null,
      currentIsActive: true,
      machines: [],
      priorities: [],
      products: [],
      jobs: [],
      moulds: [],
      categories: [],
      responsible: [],
      stoppageTypes: [],
      StoppageTypeId: null,
      Job: {
        id: null,
        note: null,
        machine: null,
        priority: null,
        mouldId: null,
        description: null,
        category: null,
        pieces: null,
        responsibleUserId: null,
        requestDate: null,
        requesterUser: null,
        requester: null,
        requestDescription: null,
        targetStartDate: null,
        targetFinishDate: null,
      },
      stoppageStartDate: null,
      stoppageFinishDate: null,
      changeWorkCenterActive: false,
      value: null,
      options: null,
      isInitialEdit: false,
    };
  },
  computed: {
    ...mapGetters({ setFieldsToValues: "pamis-job/GetJobFields" }),
  },
  watch: {
    setFieldsToValues(val) {
      if (val != null) {
        this.categories = val.categories;
        this.machines = val.machine;
        this.priorities = val.priority;
        this.products = val.products;
        this.moulds = val.products[0].moulds;
        this.responsible = val.responsibleUsers;
      }
    },
    "Job.machine": function (val) {
      if (val) {
        this.getJobEffortFields(val);
      }
    },
    "Job.id": function (val) {
      if (val) {
      }
    },
  },
  mounted() {
    this.machines = this.setFieldsToValues.machine;
  },
  methods: {
    deleteJob() {
      this.$store
        .dispatch("pamis-job/deleteJob", { id: this.Job.id })
        .then((result) => {
          if (result) {
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-job-sidebar-active", false);
            this.notification();
          }
        });
    },
    getJobEffortFields(val) {
      if (val) {
        this.$store
          .dispatch("pamis-job/fetchJobEffortFields", val)
          .then((result) => {
            if (result) {
              this.jobs = result.jobFieldDtos;
              this.stoppageTypes = result.stoppageTypes;
            }
          });
      }
    },
    formSubmitted() {
      if (!this.Job) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Job Field can not be null",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
      const jobEffort = {
        jobId: 0,
        startDate: this.Job.targetStartDate,
        endDate: this.Job.targetFinishDate,
        stoppages: this.stoppages.map((item) => ({
          type: item.type,
          startDate: item.startDate,
          endDate: item.endDate,
        })),
      };
    },
    addStoppage() {
      const newRecord = {
        startDate: this.stoppageStartDate,
        endDate: this.stoppageFinishDate,
        type: this.StoppageTypeId,
        id: `-${this.row.length}`,
        jobId: 0,
      };
      this.row.push(newRecord);
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Process Successful",
          icon: "BellIcon",
        },
      });
    },
    Delete(item) {
      const index = this.row.findIndex((x) => x.id == item.id);
      this.row.splice(index, 1);
    },
  },

  setup(props, { emit }) {
    const PAMIS_MODULE_NAME = "pamis-job";

    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, jobStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME))
        store.unregisterModule(PAMIS_MODULE_NAME);
    });

    const blankJobData = {
      note: null,
      machine: null,
      priority: null,
      id: null,
      mouldId: null,
      description: null,
      category: null,
      pieces: null,
      targetFinishDate: null,
      responsibleUserId: null,
      requestDate: null,
      requester: null,
      requesterDescription: null,
      isActive: false,
    };
    // const jobFields = computed(() => store.getters['pamis-job/GetJobFields'])
    const jobData = ref(JSON.parse(JSON.stringify(blankJobData)));
    const resetJobData = () => {
      jobData.value = JSON.parse(JSON.stringify(blankJobData));
    };
    const onSubmit = () => {
      store.dispatch("pamis-job/addJob", jobData).then(() => {});
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetJobData);

    const {
      row,
      tableColumns,
      perPage,
      currentPage,
      search,
      totalJobEfforts,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJobEffortListTable,
      refetchData,
    } = useOperatorJobList();

    return {
      row,
      tableColumns,
      perPage,
      currentPage,
      search,
      totalJobEfforts,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJobEffortListTable,
      refetchData,
      jobData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      // jobFields,
    };
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
